import React from "react";
import { motion } from "framer-motion";

export default function Arrow() {
  return (
    <motion.svg
      width="45"
      height="45"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <path
        d="M20.08 11.42L16.04 5.77C15.7 5.29 15.15 5 14.56 5C13.07 5 12.21 6.68 13.07 7.89L16 12L13.07 16.11C12.2 17.32 13.07 19 14.56 19C15.15 19 15.71 18.71 16.05 18.23L20.09 12.58C20.33 12.23 20.33 11.77 20.08 11.42Z"
        fill="black"
        fill-opacity="0.54"
      />
      <path
        d="M13.08 11.42L9.05001 5.77C8.70001 5.29 8.15001 5 7.56001 5C6.07001 5 5.20001 6.68 6.07001 7.89L9.00001 12L6.07001 16.11C5.20001 17.32 6.07001 19 7.56001 19C8.15001 19 8.71001 18.71 9.05001 18.23L13.09 12.58C13.33 12.23 13.33 11.77 13.08 11.42Z"
        fill="black"
        fill-opacity="0.54"
      />
    </motion.svg>
  );
}
