// @ts-nocheck
import React from "react";
import { motion } from 'framer-motion'

export default function Iphone() {
  const lineAnim = {
    hidden: {
      pathLength: 0
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 3,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse" 
      }
    }
  }

  return (
    <svg
      width="231"
      height="438"
      viewBox="0 0 231 438"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="iphone"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.13731 113.277C0.767529 113.368 0.324111 113.6 0.0873436 113.931C0.0385752 113.999 0 114.215 0 114.277C0 117.405 0 126.931 0 142.855C0 142.922 0.054296 143.258 0.0875412 143.339C0.27914 143.809 0.612047 143.923 1.13731 144.194H1.78719C1.78719 143.338 1.79814 114.113 1.78719 113.277H1.13731Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.13731 153.733C0.767529 153.824 0.324111 154.057 0.0873436 154.387C0.0385752 154.455 0 154.671 0 154.733C0 157.861 0 167.387 0 183.311C0 183.378 0.054296 183.714 0.0875412 183.795C0.27914 184.265 0.612047 184.379 1.13731 184.65H1.78719C1.78719 183.794 1.79814 154.569 1.78719 153.733H1.13731Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.13731 82.3594C0.509189 82.3594 0 82.8851 0 83.5336V97.9596C0 98.6081 0.509189 99.1338 1.13731 99.1338H1.78719C1.78719 98.2515 1.79814 83.2219 1.78719 82.3594H1.13731Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M229.374 129.887C230.002 129.887 230.511 131.459 230.511 133.398V176.534C230.511 178.473 230.002 180.045 229.374 180.045H228.724C228.724 177.407 228.713 132.466 228.724 129.887H229.374Z"
        fill="#333333"
      />
      <path
        d="M2.67651 37.5477C2.67651 25.0263 5.75444 15.953 11.9948 9.99354C18.2448 4.02489 27.8533 1 41.3123 1H189.274C202.733 1 212.341 4.02489 218.591 9.99354C224.832 15.953 227.91 25.0263 227.91 37.5477V400.452C227.91 412.517 224.624 421.604 218.259 427.684C211.886 433.772 202.265 437 189.274 437H41.3123C27.8533 437 18.2448 433.975 11.9948 428.006C5.75444 422.047 2.67651 412.974 2.67651 400.452V37.5477Z"
        fill="white"
        stroke="#333333"
        stroke-width="2"
      />
      <path
        d="M5.18457 37.6882C5.18457 25.7004 8.04621 17.1921 13.8789 11.6605C19.7232 6.11786 28.7452 3.37659 41.4894 3.37659H189.454C202.198 3.37659 211.22 6.11786 217.064 11.6605C222.897 17.1921 225.758 25.7004 225.758 37.6882V400.312C225.758 411.844 222.688 420.367 216.73 426.019C210.761 431.681 201.725 434.623 189.454 434.623H41.4894C28.7452 434.623 19.7232 431.882 13.8789 426.339C8.04621 420.808 5.18457 412.299 5.18457 400.312V37.6882Z"
        fill="white"
        stroke="#333333"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M139.584 19.6875C141.366 19.6875 142.81 18.3194 142.81 16.6317C142.81 14.944 141.366 13.5759 139.584 13.5759C137.803 13.5759 136.359 14.944 136.359 16.6317C136.359 18.3194 137.803 19.6875 139.584 19.6875Z"
        fill="#333333"
      />
      <path
        d="M100.956 16.6844C100.956 15.7207 101.781 14.9395 102.799 14.9395H127.445C128.463 14.9395 129.288 15.7207 129.288 16.6844C129.288 17.6482 128.463 18.4294 127.445 18.4294H102.799C101.781 18.4294 100.956 17.6482 100.956 16.6844Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.1297 16.1128C59.1297 20.9994 62.927 26.9742 71.6645 26.9742H158.692C167.43 26.9742 171.227 20.9994 171.227 16.2825C171.227 13.9312 171.227 11.7003 175.346 11.7003H192.716C207.232 11.7003 215.995 20.0051 215.995 33.7626V402.711C215.995 416.469 207.232 424.773 192.716 424.773H37.8204C23.3041 424.773 14.5413 416.469 14.5413 402.711V33.7626C14.5413 20.0051 23.3041 11.7003 37.8204 11.7003H55.0111C59.1297 11.7003 59.1297 13.9312 59.1297 16.1128Z"
        fill="white"
      />
      <rect
        x="81.1188"
        y="416.634"
        width="68.7628"
        height="2.03488"
        rx="1.01744"
        fill="#333333"
      />
      <path
        d="M60.1297 16.2263C60.1297 18.4228 60.9867 20.8845 62.8383 22.7932C64.6734 24.6849 67.5391 26.0877 71.6645 26.0877H158.692C166.859 26.0877 170.227 20.5798 170.227 16.396V16.3665C170.227 15.7993 170.227 15.1689 170.299 14.5697C170.372 13.9551 170.527 13.2999 170.886 12.7058C171.662 11.4227 173.125 10.8138 175.346 10.8138H192.716C200.161 10.8138 206.251 12.946 210.487 16.9601C214.729 20.9805 216.995 26.7794 216.995 33.8761V402.825C216.995 409.921 214.729 415.72 210.487 419.741C206.251 423.755 200.161 425.887 192.716 425.887H37.8204C30.3749 425.887 24.2848 423.755 20.0493 419.741C15.8072 415.72 13.5413 409.921 13.5413 402.825V33.8761C13.5413 26.7794 15.8072 20.9805 20.0493 16.9601C24.2848 12.946 30.3749 10.8138 37.8204 10.8138H55.0111C57.2318 10.8138 58.6878 11.4219 59.4648 12.675C60.1324 13.7519 60.1309 15.1088 60.1298 16.1077C60.1297 16.1478 60.1297 16.1874 60.1297 16.2263Z"
        stroke="#333333"
        stroke-width="2"
      />
      <motion.line
        x1="35"
        y1="119"
        x2="182"
        y2="119"
        stroke="#BDC7C5"
        stroke-width="10"
        stroke-linecap="round"
        variants={lineAnim}
        initial="hidden"
        animate="visible"
      />
      <motion.line
        x1="132"
        y1="137"
        x2="182"
        y2="137"
        stroke="#919E9C"
        stroke-width="10"
        stroke-linecap="round"
        variants={lineAnim}
        initial="hidden"
        animate="visible"
      />
      <motion.line
        x1="72"
        y1="159"
        x2="182"
        y2="159"
        stroke="#DADADA"
        stroke-width="10"
        stroke-linecap="round"
        variants={lineAnim}
        initial="hidden"
        animate="visible"
      />
      <motion.line
        x1="23"
        y1="201"
        x2="122"
        y2="201"
        stroke="#DADADA"
        stroke-width="10"
        stroke-linecap="round"
        variants={lineAnim}
        initial="hidden"
        animate="visible"
      />
    </svg>
  );
}
