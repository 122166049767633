import React from "react";
import { Text } from "@chakra-ui/react";

export const PrivacyPolicy = () => {
  return (
    <>
      <Text>
        Privacy Policy Last updated 27 May 2020
        <br />
        <br />
        Rogomi Inc. (“we” or “us” or “our”) respects the privacy of our users
        (“user” or “you”). This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you visit our mobile
        application (the “Application”). Please read this Privacy Policy
        carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY,
        PLEASE DO NOT ACCESS THE APPLICATION. We reserve the right to make
        changes to this Privacy Policy at any time and for any reason. We will
        alert you about any changes by updating the “Last updated” date of this
        Privacy Policy. You are encouraged to periodically review this Privacy
        Policy to stay informed of updates. You will be deemed to have been made
        aware of, will be subject to, and will be deemed to have accepted the
        changes in any revised Privacy Policy by your continued use of the
        Application after the date such revised Privacy Policy is posted.
        <br />
        <br />
        This Privacy Policy does not apply to the third-party online/mobile
        store from which you install the Application or make payments. We are
        not responsible for any of the data collected by any such third party.
        <br />
        <br />
        COLLECTION OF YOUR INFORMATION
        <br />
        <br />
        We may collect information about you in a variety of ways. The
        information we may collect via the Application depends on the content
        and materials you use, and includes:
        <br />
        <br />
        Personal Data
        <br />
        Demographic and other personally identifiable information (such as your
        name & picture) that you voluntarily give to us when choosing to
        participate in various activities related to the Application, such as
        registration and updating your profile. If you choose to share data
        about yourself via your profile, please be advised that all data you
        disclose in these areas will be accessible to anyone who accesses the
        Application.
        <br />
        <br />
        Derivative Data
        <br />
        Information our servers automatically collect when you access the
        Application, such as your native actions that are integral to the
        Application, including registration and updating your profile, as well
        as other interactions with the Application and other users via server
        log files.
        <br />
        <br />
        Mobile Device Access
        <br />
        We may request access or permission to certain features from your mobile
        device, including your mobile device’s camera and photos library. If you
        wish to change our access or permissions, you may do so in your device’s
        settings.
        <br />
        <br />
        Push Notifications
        <br />
        We may request to send you push notifications regarding your account or
        the Application. If you wish to opt-out from receiving these types of
        communications, you may turn them off in your device’s settings.
        <br />
        <br />
        USE OF YOUR INFORMATION
        <br />
        Having accurate information about you permits us to provide you with a
        smooth, efficient, and customized experience. Specifically, we may use
        information collected about you via the Application to: Compile
        anonymous statistical data and analysis for use internally. Create and
        manage your account. Increase the efficiency and operation of the
        Application. Monitor and analyze usage and trends to improve your
        experience with the Application. Notify you of updates to the
        Application. Perform other business activities as needed. Process
        payments and refunds. Request feedback and contact you about your use of
        the Application. Resolve disputes and troubleshoot problems. Respond to
        product and customer service requests.
        <br />
        <br />
        DISCLOSURE OF YOUR INFORMATION
        <br />
        We may share information we have collected about you in certain
        situations. Your information may be disclosed as follows:
        <br />
        <br />
        By Law or to Protect Rights
        <br />
        If we believe the release of information about you is necessary to
        respond to legal process, to investigate or remedy potential violations
        of our policies, or to protect the rights, property, and safety of
        others, we may share your information as permitted or required by any
        applicable law, rule, or regulation. This includes exchanging
        information with other entities for fraud protection and credit risk
        reduction.
        <br />
        <br />
        Third-Party Service Providers
        <br />
        We may share your information with third parties that perform services
        for us or on our behalf, including payment processing, data analysis,
        email delivery, hosting services, customer service, and marketing
        assistance.
        <br />
        <br />
        Interactions with Other Users
        <br />
        If you interact with other users of the Application, those users may see
        your name, profile photo, and descriptions of your activity.
        <br />
        <br />
        Affiliates
        <br />
        We may share your information with our affiliates, in which case we will
        require those affiliates to honor this Privacy Policy. Affiliates
        include our parent company and any subsidiaries, joint venture partners
        or other companies that we control or that are under common control with
        us.
        <br />
        <br />
        Sale or Bankruptcy
        <br />
        If we reorganize or sell all or a portion of our assets, undergo a
        merger, or are acquired by another entity, we may transfer your
        information to the successor entity. If we go out of business or enter
        bankruptcy, your information would be an asset transferred or acquired
        by a third party. You acknowledge that such transfers may occur and that
        the transferee may decline honor commitments we made in this Privacy
        Policy.
        <br />
        <br />
        We are not responsible for the actions of third parties with whom you
        share personal or sensitive data, and we have no authority to manage or
        control third-party solicitations. If you no longer wish to receive
        correspondence, emails or other communications from third parties, you
        are responsible for contacting the third party directly.
        <br />
        <br />
        TRACKING TECHNOLOGIES
        <br />
        <br />
        Mobile Analytics
        <br />
        We may also partner with selected third-party vendors, such as Firebase
        by Google to allow tracking technologies on the Application to, among
        other things, analyze and track users use of the Application, determine
        the popularity of certain content, and better understand online
        activity. By accessing the Application, you consent to the collection
        and use of your information by these third-party vendors. You are
        encouraged to review their privacy policy and contact them directly for
        responses to your questions.
        <br />
        <br />
        SECURITY OF YOUR INFORMATION
        <br />
        We use administrative, technical, and physical security measures to help
        protect your personal information. While we have taken reasonable steps
        to secure the personal information you provide to us, please be aware
        that despite our efforts, no security measures are perfect or
        impenetrable, and no method of data transmission can be guaranteed
        against any interception or other type of misuse. Any information
        disclosed online is vulnerable to interception and misuse by
        unauthorized parties. Therefore, we cannot guarantee complete security
        if you provide personal information.
        <br />
        <br />
        POLICY FOR CHILDREN
        <br />
        We do not knowingly solicit information from or market to children under
        the age of 13. If you become aware of any data we have collected from
        children under age 13, please contact us using the contact information
        provided below.
        <br />
        <br />
        OPTIONS REGARDING YOUR INFORMATION
        <br />
        <br />
        Account Information
        <br />
        You may at any time review or change the information in your account or
        terminate your account by: Logging into your account settings and
        updating your account Contacting us using the contact information
        provided below
        <br />
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        some information may be retained in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with legal requirements.
        <br />
        <br />
        Emails and Communications
        <br />
        <br />
        If you no longer wish to receive correspondence, emails, or other
        communications from us, you may opt-out by: Noting your preferences at
        the time you register your account with the Application Logging into
        your account settings and updating your preferences. Contacting us using
        the contact information provided below
        <br />
        <br />
        CONTACT US
        <br />
        <br />
        If you have questions or comments about this Privacy Policy, please
        contact us at:
        <br />
        Rogomi Inc.
        <br />
        19/F Marco Polo Ortigas, Sapphire Road
        <br />
        Pasig City, 1605, Philippines
        <br />
        +63288835302
        <br />
        veeda@rogomi.com
      </Text>
    </>
  );
};
