import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import "../assets/css/main.css";

type TMainProps = {
  children: React.ReactNode;
};

export default function Main({ children }: TMainProps) {
  return (
    <ChakraProvider resetCSS>
      <main>{children}</main>
    </ChakraProvider>
  );
}
