// @ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";
import logo from "../images/nav/logo.svg";

type TSeoProps = {
  title: string;
  url: string;
  description: string;
  fbId?: string;
};

export default function Seo(props: TSeoProps) {
  const { title, description, url, fbId } = props;

  return (
    <Helmet>
      <title>{title}</title>

      <link rel="icon" type="image/png" sizes="16x16" href={logo} />

      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description} />

      {/* opengraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      {fbId && <meta property="fb:app_id" content={fbId} />}

      {/* twitter tags */}

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        rel="preload"
        href="https://fonts.googleapis.com/css2?family=Poppins&display=swap"
        as="font"
        type="font/woff2"
        crossorigin
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap"
        rel="stylesheet"
      ></link>

      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&display=swap"
        rel="stylesheet"
      />
      <link href="https://unpkg.com/pattern.css" rel="stylesheet"></link>

      {/* Google analytics */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-7FE11DTHTQ"
      ></script>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WSP4MW7T');`}
      </script>
    </Helmet>
  );
}
