import React from "react";
import { Flex, Button, Image } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { Burger, Arrow } from "./Graphics";
import { Link } from "gatsby";
// @ts-ignore
import logo from "../images/nav/logo.svg";
// @ts-ignore
import rogomi from "../images/nav/rogomi.svg";

type TMobileNavProps = {
  active: string;
  setActiveNav: (active: string, pageName: string) => string;
};

export default function MobileNav({ active, setActiveNav }: TMobileNavProps) {
  const [sideBarOpen, setSideBarOpen] = React.useState(false);

  const navItems = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const navHovered = {
    color: "#32AAB5",
  };

  return (
    <Flex
      display={["flex", "flex", "flex", "none", "none"]}
      justifyContent={"flex-end"}
      w="90%"
    >
      <AnimatePresence>
        {!sideBarOpen && (
          <motion.button onClick={() => setSideBarOpen((prev) => !prev)}>
            <Burger />
          </motion.button>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {sideBarOpen && (
          <motion.div
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
            exit={{ x: 500 }}
            className={"mobileNav"}
          >
            <Flex
              backgroundColor={"#fffff"}
              h="50vh"
              flexDirection={"column"}
              position="fixed"
            >
              <Button
                variant="unstyled"
                onClick={() => setSideBarOpen((prev) => !prev)}
                h="10rem"
                w="100%"
                display={"flex"}
                alignItems="center"
              >
                <Arrow />
              </Button>
              <motion.ul
                className="mobileNavList"
                initial="hidden"
                animate="show"
                transition={{
                  delay: 1.5,
                  staggerChildren: 0.25,
                }}
              >
                <motion.li
                  whileHover={navHovered}
                  className={setActiveNav(active, "home")}
                  variants={navItems}
                >
                  <Link to="/">Home</Link>
                </motion.li>
                <motion.li
                  whileHover={navHovered}
                  className={setActiveNav(active, "services")}
                  variants={navItems}
                >
                  <Link to="/services">Our Work</Link>
                </motion.li>
                <motion.li
                  whileHover={navHovered}
                  className={setActiveNav(active, "about_us")}
                  variants={navItems}
                >
                  <Link to="/about-us">Our Story</Link>
                </motion.li>
                <motion.li
                  whileHover={navHovered}
                  className={setActiveNav(active, "contact_us")}
                  variants={navItems}
                >
                  <Link to="/contact-us">Contact Us</Link>
                </motion.li>
              </motion.ul>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  );
}
