// @ts-nocheck
import React from "react";
import { motion } from "framer-motion";

export default function Ipad() {
  const bubbleAnim = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 5,
        repeat: Infinity,
        repeatType: "mirror",
      },
    },
    hidden: {
      opacity: 0,
      y: -50,
    },
  };

  const bubbleAnim1 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "mirror",
      },
    },
    hidden: {
      opacity: 0,
      y: -100,
    },
  };

  const bubbleAnim2 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 5,
        repeatType: "mirror",
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
    hidden: {
      opacity: 0,
      y: 100,
    },
  };

  return (
    <svg
      width="419"
      className="ipad"
      height="562"
      viewBox="0 0 419 562"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2301 561C11.8163 561 1 550.493 1 537.603L1 24.3969C1 11.5065 11.8163 1 25.2301 1H393.77C407.184 1 418 11.5065 418 24.3969V537.603C418 550.493 407.184 561 393.77 561H25.2301Z"
        fill="white"
        stroke="#333333"
        stroke-width="2"
      />
      <path
        d="M209.5 532.504C215.2 532.504 219.764 536.964 219.764 542.395C219.764 547.827 215.2 552.287 209.5 552.287C203.8 552.287 199.237 547.827 199.237 542.395C199.237 536.964 203.8 532.504 209.5 532.504Z"
        fill="white"
        stroke="#333333"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M213.104 21.3472C213.104 19.4224 211.491 17.8619 209.5 17.8619C207.509 17.8619 205.896 19.4224 205.896 21.3472C205.896 23.2721 207.509 24.8325 209.5 24.8325C211.491 24.8325 213.104 23.2721 213.104 21.3472Z"
        fill="#333333"
      />
      <motion.rect
        x="278.941"
        y="394.765"
        width="108.281"
        height="30.7166"
        rx="10"
        fill="#E5E5E5"
        variants={bubbleAnim2}
        animate={"visible"}
        initial={"hidden"}
      />
      <motion.rect
        x="196.553"
        y="315.13"
        width="190.669"
        height="62.5709"
        rx="10"
        fill="#D0D0D0"
        variants={bubbleAnim2}
        animate={"visible"}
        initial={"hidden"}
      />
      <motion.rect
        x="196.553"
        y="129.692"
        width="190.669"
        height="81.9109"
        rx="10"
        fill="#E5E5E5"
        variants={bubbleAnim1}
        animate={"visible"}
        initial={"hidden"}
      />
      <motion.rect
        x="30.6011"
        y="240.045"
        width="190.669"
        height="56.8826"
        rx="10"
        fill="#919E9C"
        variants={bubbleAnim}
        animate={"visible"}
        initial={"hidden"}
      />
      <rect
        x="22.6259"
        y="521.791"
        width="482.453"
        height="373.748"
        transform="rotate(-90 22.6259 521.791)"
        stroke="#333333"
        stroke-width="2"
      />
    </svg>
  );
}
